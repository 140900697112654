import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {client} from "../../services/api.service";

interface ITag {
    id: number;
    name: string;
}
export interface ISingleNews {
    id: number,
    title: string,
    text: string,
    subtitle: string,
    videoUrl: string,
    tag: number,
    newsDate: string,
    image: {
        id: number,
        newsId: number,
        image: string
    }

}

export interface INewsItem {
    id: number;
    title: string;
    tagName: string;
    image: string;
}

export interface INewsByTag {
    tag:{
        id:number,
        name:string
    }
    news: INewsItem[];
}

export interface IMewsInitResponse {
    nextLiveEvent: string;
    tags: ITag[];
    newsCarousel: INewsItem[];
    newsByTag: INewsByTag;
}

interface NewsState {
    newsCarousel: INewsItem[];
    newsByTag: INewsByTag | null;
    loader: boolean;
    newByTagLoader: boolean,
    nextLiveEvent: string;
    tags: ITag[]
}

const initialState: NewsState = {
    tags: [],
    newsCarousel: [],
    newsByTag: null,
    loader: false,
    newByTagLoader: false,
    nextLiveEvent: ""

};


export const fetchMssNews = createAsyncThunk<IMewsInitResponse>(
    'news-get-news-init',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/mss-web/news-init`);
            return data;
        } catch (error) {
            // @ts-ignore
            return rejectWithValue(error.response.data)
        }

    }
)

export const fetchMssNewsByTag = createAsyncThunk<INewsByTag, number | null>(
    'get-news-by-tag',
    async (tagId, {rejectWithValue}) => {
        try {
            if (tagId !== null) {
                const news = await client.get(`/api/mss-web/news-by-tag/?tag=${tagId}`);
                return news.data;
            } else {
                const news = await client.get(`/api/mss-web/news-by-tag`);
                return news.data;
            }
        } catch (error) {
            // @ts-ignore
            return rejectWithValue(error.response.data)
        }

    }
)

export const getNewsCarousel = createAsyncThunk<INewsItem[]>(
    'get-news-carousel',
    async (_, {rejectWithValue}) => {
        try {
            const news = await client.get('/api/mss-web/news-carousel');
            return news.data;
        } catch (error) {
            // @ts-ignore
            return rejectWithValue(error.response.data)
        }

    }
)

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMssNews.pending, (state) => {
                state.loader = true;
            })
            .addCase(fetchMssNews.fulfilled, (state, action: PayloadAction<IMewsInitResponse>) => {
                state.loader = false;
                state.newsCarousel = action.payload.newsCarousel
                state.newsByTag = action.payload.newsByTag
                state.tags = action.payload.tags
                state.nextLiveEvent = action.payload.nextLiveEvent
            })
            .addCase(fetchMssNews.rejected, (state, action: PayloadAction<any>) => {
                state.loader = false;
            })
            .addCase(fetchMssNewsByTag.pending, (state) => {
                state.newByTagLoader = true;
            })
            .addCase(fetchMssNewsByTag.fulfilled, (state, action: PayloadAction<INewsByTag>) => {
                state.newByTagLoader = false;
                state.newsByTag = action.payload;
            })

            .addCase(getNewsCarousel.fulfilled, (state, action: PayloadAction<INewsItem[]>) => {
                state.newsCarousel = action.payload;
            })
            .addCase(getNewsCarousel.rejected, (state, action: PayloadAction<any>) => {

            });
    },
});

export default newsSlice.reducer;
