import {configureStore} from '@reduxjs/toolkit';
import rootReducer from './index';

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({thunk: true}),

    devTools: {
        trace: true,
        traceLimit: 25,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

;

