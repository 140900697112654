import React, {useEffect} from "react";
import topSvg from "../../assets/img/announcement/top.svg";
import botSvg from "../../assets/img/announcement/bottom.svg";
import {Link} from "react-router-dom";
import moment from "moment";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {getNewsCarousel, ISingleNews} from "../../redux/slices/newsSlice";

interface ISingleNewsItemsProps {
    news:ISingleNews
}

export const SingleNewsItem:React.FC<ISingleNewsItemsProps>= ({news}) => {
    const dispatch = useDispatch()
    const {newsCarousel} = useTypedSelector(state => state.news)

    useEffect(() => {
      dispatch(getNewsCarousel())
    }, []);

    const generateLastNews = () => {
        return (
            <>
                {newsCarousel.map((newsItem) => {
                    return (
                        <div key={newsItem.id} className="newsItem col-xl-12">
                            <div className="body">
                                {newsItem.image &&
                                    <img
                                        src={`data:image/png;base64,${newsItem.image}`}
                                        alt="Article Title"
                                    />
                                }
                                <div className="category">
                                    {newsItem.tagName}
                                </div>
                                <h3>
                                    <Link to={`/singleNews/${newsItem?.id}`}>
                                        {newsItem?.title}
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    )
                })
                }
            </>
        )
    }

    return (
        <>
            <div className="headerSpace"/>

            <img src={`data:image/png;base64,${news.image.image}`} className="main hideDesktop" width="100%"
                 alt="Article Title"
            />

            <div className="newsSingleTitle">

                <img src={topSvg} className="top hideDesktop" alt={"topSvg"}/>
                <img src={botSvg} className="bottom hideDesktop" alt={"botSvg"}/>

                <div className="wrap">
                    <div className="date hideDesktop">
                        {moment(news.newsDate).format('L')}
                    </div>

                    <div className="sub">Score Update</div>
                    <h1>{news.title}</h1>
                    <p className="hideDesktop"><strong>{news.subtitle}</strong>
                    </p>
                </div>
            </div>

            <div className="featuredImg hideMobile">
                <div className="wrap">
                    <img src={`data:image/png;base64,${news.image.image}`} className="main"
                         alt="Article Title"/>
                </div>
                <div className="fill">
                    <img src={topSvg} className="top" alt={"topSvg"}/>
                    <img src={botSvg} className="top" alt={"botSvg"}/>
                </div>
            </div>

            <div className="wrap">
                <div className="row">
                    <div className="col-12 col-xl-12">
                        <div className="articleContents">
                            <div className="date hideMobile">
                                {moment(news.newsDate).format('L')}
                            </div>

                            <p className="hideMobile"><strong>{news.subtitle}</strong></p>
                            <div dangerouslySetInnerHTML={{__html: `${news.text}`}}/>
                        </div>

                        <a href="#" className="backToTop">
                            Back To Top
                        </a>

                    </div>
                    <div className="col-12 col-xl-4 d-flex mb-lg-5 mt-lg-5 hideMobile">
                        {newsCarousel.length && generateLastNews()}
                    </div>
                </div>
            </div>

            <div className="announcement newsSliderMob hideDesktop">
                <img src={topSvg} className="top"/>
                <div className="wrap">
                    {newsCarousel.length && generateLastNews()}
                </div>
            </div>
            <div id="returnStatus"/>
        </>
    )
}

