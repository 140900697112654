import {combineReducers} from 'redux';
import newsSlice from "./slices/newsSlice";
import fixturesSlice from "./slices/fixturesSlice";


export default combineReducers({
    fixtures: fixturesSlice,
    news: newsSlice
});

