import axios from 'axios';

const client = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity',
        'Access-Control-Allow-Origin': '*',
    },
    baseURL: process.env.REACT_APP_API_ENDPOINT,
});


client.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 429) {

            alert(error.response.data);
        }
        return Promise.reject(error);
    }
);

export { client };