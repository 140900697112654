import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {client} from '../../services/api.service';
import {AxiosError} from 'axios';

export interface IFixtureStats {
    totalScore: number;
    turns100to139: number;
    turns140to179: number;
    turns180: number;
    highestCheckout: number;
    checkouts100plus: number;
    average: number;
    checkoutPercentage: number;
    checkouts: string;
}


interface IPlayer {
    id: number;
    name: string;
}

export interface IMatchStatistics {
    playersStatistics: {
        players: IPlayer[];
        statistics: IFixtureStats[];
    }
    groupName: string;
    players: IPlayer[];
    statistics: IFixtureStats[];
    sequential?: number;
    seriesName: string;
    startDateTime: string;
}

interface IPreSelectedInfo {
    series: number;
    week: number;
    group: number;
    hasValue: boolean;
}

interface IFilterOption {
    id: number;
    name: string;
}

interface IMssFixturesTableData {
    weekNumber: number;
    seria: string;
    weekWinner: string;
    data: any[];
}

interface IPageData {
    mssFixturesTables: IMssFixturesTableData;
}

interface IFilters {
    series: IFilterOption[];
    weeks: IFilterOption[];
    groups: IFilterOption[];
}

export interface IResultsInitResponse {
    selected: IPreSelectedInfo
    filters: IFilters
    pageData: IPageData
    winnerTitle: string
    winnerName: string
    year: number
    fixtures: IFixture[]
    table: ITableRow[]

}

export interface IResultsInit {
    pageData: IPageData
    isLoading: boolean
    preSelectedInfo: IPreSelectedInfo
    fixtures: IFixture[]
    table: ITableRow[]
    winnerTitle: string
    winnerName: string
    filters: IFilters
    year: number
    oneMatchStatistic: IMatchStatistics
    fixtureLoader: boolean
    preselectComplete: boolean
}

export interface ITableRow {
    player: IFilterOption;
    p: number;
    w: number;
    l: number;
    pts: number;
    diff: number;
    legs: number;
}

export interface IFixture {
    gameId: number;
    fixture: Date;
    playerHome: string;
    playerAway: string;
    scorePlayerHome: number;
    scorePlayerAway: number;
    groupName?: string;
}


const initialState: IResultsInit = {
    pageData: {
        mssFixturesTables: {
            data: [],
            seria: '',
            weekNumber: 0,
            weekWinner: '',
        },
    },
    filters: {
        series: [],
        groups: [],
        weeks: [],
    },
    table: [],
    fixtures: [],
    preSelectedInfo: {
        series: 0,
        week: 0,
        hasValue: false,
        group: 0,
    },
    winnerName: '',
    winnerTitle: '',
    year: 0,
    isLoading: true,
    oneMatchStatistic: {} as IMatchStatistics,
    fixtureLoader: false,
    preselectComplete: false
};

export const getFixturesTable = createAsyncThunk<IResultsInitResponse>(
    'fixtures/get-fixture-table',
    async (_, {rejectWithValue}) => {
        try {
            const results = await client.get(`api/mss-web/results-fixtures`);
            return results.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);

export const getFilteredFixtures = createAsyncThunk<IResultsInitResponse, string>(
    'fixtures/get-filtered-fixtures',
    async (query, {rejectWithValue}) => {
        try {
            const results = await client.get(`api/mss-web/results-fixtures${query}`);
            return results.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);
export const getOneMatchStatistic = createAsyncThunk<IMatchStatistics, string>(
    'fixtures/get-one-match-statistic',
    async (gameId, {rejectWithValue}) => {
        try {
            const results = await client.get(`/api/mss-web/fixtures/${gameId}`);
            return results.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);

const fixturesSlice = createSlice({
    name: 'fixtures',
    initialState,
    reducers: {
        setSelectedWeek(state, action: PayloadAction<number>) {
            state.preSelectedInfo.week = action.payload;
        },
        setSelectedGroup(state, action: PayloadAction<number>) {
            state.preSelectedInfo.group = action.payload;
        },
        setSelectedSeries(state, action: PayloadAction<number>) {
            state.preSelectedInfo.series = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFixturesTable.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFixturesTable.fulfilled, (state, action) => {
                const {pageData, table, fixtures, selected, year, winnerTitle, winnerName, filters} = action.payload;
                state.pageData = pageData;
                state.table = table;
                state.fixtures = fixtures;
                state.preSelectedInfo = selected;
                state.year = year;
                state.winnerTitle = winnerTitle;
                state.winnerName = winnerName;
                state.filters = filters;
                state.isLoading = false;
                state.preselectComplete = true
            })
            .addCase(getFixturesTable.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getFilteredFixtures.pending, (state) => {
                state.fixtureLoader = true;
            })
            .addCase(getFilteredFixtures.fulfilled, (state, action) => {
                const {pageData, table, fixtures, selected, year, winnerTitle, winnerName, filters} = action.payload;
                state.pageData = pageData;
                state.table = table;
                state.fixtures = fixtures;
                state.preSelectedInfo = selected;
                state.year = year;
                state.winnerTitle = winnerTitle;
                state.winnerName = winnerName;
                state.filters = filters;
                state.fixtureLoader = false;
            })
            .addCase(getFilteredFixtures.rejected, (state) => {
                state.fixtureLoader = false;
            })
            .addCase(getOneMatchStatistic.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOneMatchStatistic.fulfilled, (state, action) => {
                state.isLoading = false;
                state.oneMatchStatistic = action.payload
            })
            .addCase(getOneMatchStatistic.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

export const {setSelectedWeek, setSelectedGroup, setSelectedSeries} = fixturesSlice.actions;

export default fixturesSlice.reducer;
