import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import topSvg from '../../assets/img/announcement/top.svg';
import botSvg from '../../assets/img/announcement/bottom.svg';
import {Link} from "react-router-dom";
import {Tab, Tabs} from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import '../../assets/css/global.scss';
import '../../assets/css/animate.css';
import {fetchMssNews, fetchMssNewsByTag, INewsItem} from "../../redux/slices/newsSlice";


const AllNews: React.FC = () => {
    const dispatch = useDispatch();
    const {newsCarousel, newsByTag, nextLiveEvent, loader, newByTagLoader} = useTypedSelector(state => state.news);
    const [currentTab, setCurrentTab] = useState<number | null>(null);

    useEffect(() => {
        dispatch(fetchMssNews())
    }, [])

    const getNewsType = (tag: string | null) => {
        switch (tag) {
            case 'blogs':
                return 0;
            case 'announcements':
                return 1;
            case 'press-releases':
                return 2;
            default:
                return null
        }
    }

    const onGetNewsByTag = (tagId: number | null) => {
        setCurrentTab(tagId)
        dispatch(fetchMssNewsByTag(tagId))
    }

    const NewsCarouselItem = (title: string, id: number, image: string, tag: string) => {
        return (
            <div className="news-carousel-item">
                <div className={"img-inner"}>
                    <img src={`data:image/png;base64,${image}`} alt="Article Title"/>
                </div>
                <div className="contents">
                    <div className="category">
                        {tag}
                    </div>
                    <h3>
                        <Link to={`/singleNews/${id}`}>{title}</Link>
                    </h3>
                </div>
            </div>
        )
    }

    const NewsByTagItem = (title: string, id: number, image: string, tag: string) => {
        return (
            <div className="newsItemSmall">
                <img src={`data:image/png;base64,${image}`} alt="Article Title"/>
                <div className="contents">
                    <div className="category">
                        {tag}
                    </div>
                    <h3>
                        <Link to={`/singleNews/${id}`}>{title}</Link>
                    </h3>
                </div>
            </div>
        )
    }

    const getNewsIntoSlider = (sliderCount: number) => {
        return (
            <div className='swiper-container'>
                <Swiper
                    slidesPerView={sliderCount}
                    speed={600}
                    modules={[Navigation]}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                    loop
                >
                    {
                        newsCarousel?.map((item: INewsItem) => {
                            return <div key={item.id}>
                                <SwiperSlide
                                    key={item.id}>{NewsCarouselItem(item.title, item.id, item.image, item.tagName)}
                                </SwiperSlide>
                            </div>
                        })

                    }
                </Swiper>
                <div className="swiper-button-prev"/>
                <div className="swiper-button-next"/>
            </div>
        )
    }

    return (
        <>
            <div className="headerSpace"/>
            <div className="newsTitle">
                <div className="wrap">
                    <h1>Latest News</h1>
                </div>
            </div>

            {!loader ?
                <>
                    <div className="newsSlider">
                        <div className="wrap hideDesktop">
                            {getNewsIntoSlider(1)}
                        </div>

                        <div className="wrap hideMobile">
                            {getNewsIntoSlider(2.5)}
                        </div>

                    </div>
                    <div className="announcement">
                        <img src={topSvg} className="top" alt={"topSvg"}/>
                        <img src={botSvg} className="bottom" alt={"botSvg"}/>
                        <div className="wrap">
                            <div className="sub">Next Live Event</div>
                            <h3 className={"next-event-title"}>{nextLiveEvent}</h3>
                        </div>
                    </div>
                </>
                :
                <Loader/>

            }
            {!loader &&
                <div className="extraNews">
                    <div className="wrap">
                        <Tabs
                            defaultActiveKey="all"
                            id="controlled-tab-example"
                            className="mb-3"
                            onSelect={(tabName) => onGetNewsByTag(getNewsType(tabName))}
                        >
                            <Tab eventKey="all" title="All">
                                {!newByTagLoader ?
                                    <div className="row">
                                        {currentTab === null &&
                                            newsByTag?.news?.map((item) => {
                                                return (
                                                    <div>
                                                        <div className="col-12 col-xl-6" key={item.id}>
                                                            {NewsByTagItem(item.title, item.id, item.image, item.tagName)}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </Tab>
                            <Tab
                                eventKey="blogs"
                                title="Blogs"
                            >
                                {!newByTagLoader ?
                                    <div className="row">
                                        {currentTab === 0 &&
                                            newsByTag?.news?.map((item) => {
                                                return (
                                                    <div className="col-12 col-xl-6" key={item.id}>
                                                        {NewsByTagItem(item.title, item.id, item.image, item.tagName)}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </Tab>
                            <Tab
                                eventKey="announcements"
                                title="Announcements"
                            >
                                {!newByTagLoader ?
                                    <div className="row">
                                        {currentTab === 1 &&
                                            newsByTag?.news?.map((item) => {
                                                return (
                                                    <div className="col-12 col-xl-6" key={item.id}>
                                                        {NewsByTagItem(item.title, item.id, item.image, item.tagName)}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </Tab>
                            <Tab
                                eventKey="press-releases"
                                title="press releases"
                            >
                                {!newByTagLoader ?
                                    <div className="row">
                                        {currentTab === 2 &&
                                            newsByTag?.news?.map((item) => {
                                                return (
                                                    <div className="col-12 col-xl-6" key={item.id}>
                                                        {NewsByTagItem(item.title, item.id, item.image,item.tagName)}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            }
        </>
    )
}

export default AllNews;