import {IFixtureLabel} from "../Results";
import {IFixture} from "../../../redux/slices/fixturesSlice";

export const getTypeOfFixture = (fixture: IFixture) => {
    if (!fixture.scorePlayerAway && !fixture.scorePlayerHome) {
        return "fixture pending"
    } else {
        return "fixture"
    }
}

export const formatPlayerScore = (fixture: IFixture, score: number) => {
    if (!fixture.scorePlayerAway && !fixture.scorePlayerHome) {
        return "?"
    } else return score
};



export const getFixtureLabel = (fixture: IFixture): IFixtureLabel => {
    let label: IFixtureLabel = {} as IFixtureLabel;
    switch (fixture.groupName) {
        case 'FinalGroup1':
            return label = {
                label: 'GROUP 1',
                labelClass: 'col-5 col-sm-3 matchRank'
            }
        case 'FinalGroup2':
            return label = {
                label: 'GROUP 2',
                labelClass: 'col-5 col-sm-3 matchRank'
            }
        case 'SemiFinal':
            return label = {
                label: 'SF',
                labelClass: 'col-5 col-sm-3 matchRankSf'
            }
        case 'PlayOff3dplace':
            return label = {
                label: 'QF',
                labelClass: 'col-5 col-sm-3 matchRankQf'
            }
        case 'Final':
            return label = {
                label: 'FINAL',
                labelClass: 'col-5 col-sm-3 matchRankFinal'
            }
    }
    return label;
}
