import React, {ReactElement} from "react";
import {removeNameSeparator} from "../../../helpers/rmNameSeparator";
import {ITableRow} from "../../../redux/slices/fixturesSlice";

 export const generateTable = (item: ITableRow, index: number): ReactElement => {
    return (
        <div key={index + 1} className="row wow fadeIn">
            <div className="col-1">
                <div className="stat white">
                    {index + 1}
                </div>
            </div>
            <div className="col-4">
                <div className="name">
                    {removeNameSeparator(item.player.name)}
                </div>
            </div>
            <div className="col-1">
                <div className="stat">
                    {item.p}
                </div>
            </div>
            <div className="col-1">
                <div className="stat">
                    {item.w}
                </div>
            </div>
            <div className="col-1">
                <div className="stat">
                    {item.l}
                </div>
            </div>
            <div className="col-1">
                <div className="stat">
                    {item.diff}
                </div>
            </div>
            <div className="col-1">
                <div className="points">
                    {item.pts}
                </div>
            </div>
        </div>
    )
}

